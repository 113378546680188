<template lang="">
    <div class="faq__page">
        <a-card
            class="header-solid h-full p-20"
            :bordered="false"
            :style="{ borderRadius: '16px' }"
            :bodyStyle="{ padding: 0 }"
        >
            <div class="faq__group">
                <div class="faq__title">
                    <h2>{{ $t('cms.FAQ') }}</h2>
                    <a-input-search v-model="search" :placeholder="$t('header.search')" @change="onSearchItem" />
                </div>
                <div class="text-center my-15" v-if="isLoading">
                    <a-spin size="large" />
                </div>
                <div class="faq__collapse" v-if="!isLoading">
                    <a-collapse :bordered="false" expandIconPosition="right">
                        <template #expandIcon="props">
                            <div>
                                <a-icon
                                    type="right"
                                    :rotate="props.isActive ? 90 : 0"
                                    :style="{ fontSize: '16px', color: '#2d73ff' }"
                                />
                            </div>
                        </template>
                        <a-collapse-panel v-for="item in faqList" :key="item.id" :header="item.question">
                            <div class="faq__answer mb-0">
                                <MyEditor :data="item.answer" :isReadOnly="true" />
                            </div>
                        </a-collapse-panel>
                    </a-collapse>
                    <div class="text-center py-5">
                        <a-empty :description="$t('no_data')" v-if="!faqList.length" />
                    </div>
                </div>
                <div class="text-center py-3" v-if="faqPagination.totalPages > 1">
                    <f-pagination
                        v-model="page"
                        :pageSize="faqPagination.pageSize"
                        :totalItems="faqPagination.totalItems"
                        :disabled="isLoading"
                    />
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
import MyEditor from '../../components/CMS/MyEditor';
import { mixinToasts } from '../../mixins';
import { mapState, mapActions } from 'vuex';
import * as _ from 'lodash';

export default {
    mixins: [mixinToasts],
    components: {
        MyEditor,
    },
    data() {
        return {
            page: 1,
            search: '',
        };
    },
    created() {
        this.getList({ page: this.page, field: 'question', search: this.search });
    },
    computed: {
        ...mapState('faq', ['isLoading', 'faqList', 'faqPagination']),
    },
    watch: {
        page(val) {
            this.getList({ page: val, field: 'question', search: this.search });
        },
    },
    methods: {
        ...mapActions('faq', ['getFaqList', 'postNewFAQ', 'putFAQ', 'deleteFAQ']),

        async getList(data) {
            this.page = data?.page || 1;
            this.search = data?.search || '';
            await this.getFaqList(data);
        },

        onSearchItem: _.debounce(function (e) {
            this.getList({ page: 1, field: 'question', search: this.search });
        }, 500),
    },
};
</script>

<style lang="scss">
.faq__page {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: 40px auto;
}

.faq__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    & h2 {
        margin-bottom: 0px;
        font-size: 20px;
    }
    & .ant-select {
        margin-right: 10px;
        width: 175px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.faq__collapse {
    & .ant-collapse-header {
        padding: 20px 40px 20px 0 !important;
        font-size: 16px;
        font-weight: 600;
    }
    & .ant-collapse-content-box {
        padding: 0px 0 30px !important;
    }
    & .ant-collapse-item {
        background-color: #fff;
        &:last-child {
            border-bottom: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .faq__title {
        flex-direction: column;

        & .ant-input-search {
            margin-top: 15px;
            width: 100%;
        }
    }
}
</style>
